import { Skeleton } from '@chakra-ui/react'
import { Avatar, Tag, UserCircles } from '@components/core'
import Layout from '@components/Layout/Layout'
import { Tab } from '@components/NewDashboard/Tabs'
import StyledLoading from '@components/StyledLoading'
import useFeaturedUsers from '@hooks/query/useFeaturedUsers'
import useInfiniteUsers from '@hooks/query/useInfiniteUsers'
import useProfile from '@hooks/query/useProfile'
import useQueryParam from '@hooks/useQueryParam'
import useWindowSize from '@hooks/useWindowSize'
import clsx from 'clsx'
import type { NextPage } from 'next'
import Link from 'next/link'
import { FC, useMemo } from 'react'
import { shortenAddressWithNoDots } from 'utils'
import { User } from '../interfaces/interfaces'

const Creators: NextPage = () => {
	const [tab, setTab] = useQueryParam<TabType>('tab', {
		defaultValue: TabType.FEATURED,
		allowedValues: Object.values(TabType),
	})

	const renderTabs = useMemo(() => {
		switch (tab) {
			case TabType.FEATURED:
				return <FeaturedCreators />
			case TabType.ALL:
				return <AllCreators />
		}
	}, [tab])

	return (
		<Layout title="Creators">
			<div className="w-full grid grid-cols-16 mb-20 lg:pl-20">
				<div className="w-full flex flex-col lg:flex-row items-center justify-between px-4 py-10 col-span-full lg:col-span-12 2xl:col-span-13 border-0 lg:border-b border-color-3">
					<div className="flex items-center">
						{/* Creators Icon */}
						<svg
							width="44"
							height="35"
							className="stroke-color-6"
							viewBox="0 0 44 35"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle
								cx="17.3416"
								cy="17.4605"
								r="15.2825"
								transform="rotate(89.7775 17.3416 17.4605)"
								strokeWidth="3"
							/>
							<circle
								cx="26.6581"
								cy="17.3418"
								r="15.2825"
								transform="rotate(89.7775 26.6581 17.3418)"
								strokeWidth="3"
							/>
						</svg>
						<h1 className="ml-2 mt-1 font-semibold text-lg 2xl:text-xl tracking-[0.01em] text-color-6">CREATORS</h1>
					</div>
					{/* Tabs */}
					<div className="flex items-center justify-center lg:justify-between w-full lg:w-min px-4 py-3 rounded-full lg:bg-color-0 gap-4 mt-10 lg:mt-0">
						<Tab
							id={TabType.FEATURED}
							currentTab={tab}
							imageComponent={
								<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M10.6201 17.7726C10.5157 17.7731 10.4127 17.7484 10.3198 17.7008L6.99005 15.9576L3.6603 17.7008C3.55218 17.7577 3.43027 17.7831 3.30844 17.7741C3.18661 17.7651 3.06975 17.7221 2.97113 17.65C2.87251 17.5779 2.7961 17.4796 2.75057 17.3663C2.70505 17.2529 2.69224 17.129 2.71361 17.0088L3.3665 13.333L0.676587 10.7214C0.592663 10.6377 0.533128 10.5326 0.504372 10.4176C0.475616 10.3026 0.478725 10.1819 0.513364 10.0685C0.551206 9.95249 0.620815 9.84938 0.714292 9.77091C0.807769 9.69243 0.921371 9.64173 1.04221 9.62456L4.76369 9.08266L6.40245 5.73332C6.45591 5.62294 6.53938 5.52984 6.64331 5.46471C6.74723 5.39957 6.8674 5.36502 6.99005 5.36502C7.1127 5.36502 7.23287 5.39957 7.33679 5.46471C7.44072 5.52984 7.52419 5.62294 7.57765 5.73332L9.236 9.07613L12.9575 9.61803C13.0783 9.6352 13.1919 9.68591 13.2854 9.76438C13.3789 9.84285 13.4485 9.94596 13.4863 10.062C13.521 10.1754 13.5241 10.2961 13.4953 10.4111C13.4666 10.5261 13.407 10.6311 13.3231 10.7149L10.6332 13.3265L11.2861 17.0022C11.3094 17.1246 11.2972 17.2512 11.2509 17.3668C11.2046 17.4825 11.1262 17.5826 11.0249 17.6551C10.9067 17.738 10.7643 17.7793 10.6201 17.7726Z"
										className={tab === TabType.FEATURED ? 'fill-[url(#g6)]' : 'fill-color-6'}
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M6.99981 0.401123C7.32649 0.401123 7.59132 0.665953 7.59132 0.992637V2.97679C7.59132 3.30347 7.32649 3.5683 6.99981 3.5683C6.67312 3.5683 6.40829 3.30347 6.40829 2.97679V0.992637C6.40829 0.665953 6.67312 0.401123 6.99981 0.401123ZM11.2626 2.73897C11.5893 2.73897 11.8541 3.0038 11.8541 3.33048V7.18106C11.8541 7.50774 11.5893 7.77257 11.2626 7.77257C10.9359 7.77257 10.6711 7.50774 10.6711 7.18106V3.33048C10.6711 3.0038 10.9359 2.73897 11.2626 2.73897ZM3.12637 3.33048C3.12637 3.0038 2.86154 2.73897 2.53486 2.73897C2.20817 2.73897 1.94334 3.0038 1.94334 3.33048V7.18106C1.94334 7.50774 2.20817 7.77257 2.53486 7.77257C2.86154 7.77257 3.12637 7.50774 3.12637 7.18106V3.33048Z"
										className={tab === TabType.FEATURED ? 'fill-[url(#g7)]' : 'fill-color-6'}
									/>
									<defs>
										<linearGradient
											id="g6"
											x1="13.8192"
											y1="3.43445"
											x2="-0.371571"
											y2="4.24987"
											gradientUnits="userSpaceOnUse"
										>
											<stop stopColor="#E8B05D" />
											<stop offset="0.28125" stopColor="#ED6F4D" />
											<stop offset="0.807292" stopColor="#7765CB" />
											<stop offset="1" stopColor="#4F81E2" />
										</linearGradient>
										<linearGradient
											id="g7"
											x1="12.0857"
											y1="-0.745543"
											x2="1.3146"
											y2="0.0470331"
											gradientUnits="userSpaceOnUse"
										>
											<stop stopColor="#E8B05D" />
											<stop offset="0.28125" stopColor="#ED6F4D" />
											<stop offset="0.807292" stopColor="#7765CB" />
											<stop offset="1" stopColor="#4F81E2" />
										</linearGradient>
									</defs>
								</svg>
							}
							onSelect={setTab}
						/>
						<Tab
							id={TabType.ALL}
							currentTab={tab}
							imageComponent={
								<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M10.6201 12.6033C10.5157 12.6038 10.4127 12.5791 10.3198 12.5315L6.99003 10.7883L3.66029 12.5315C3.55216 12.5884 3.43026 12.6138 3.30843 12.6048C3.1866 12.5958 3.06973 12.5528 2.97111 12.4807C2.87249 12.4086 2.79608 12.3103 2.75056 12.1969C2.70503 12.0836 2.69223 11.9597 2.71359 11.8395L3.36649 8.16367L0.676572 5.5521C0.592647 5.46835 0.533113 5.36333 0.504357 5.24831C0.475601 5.13329 0.47871 5.0126 0.513349 4.89921C0.55119 4.78318 0.620799 4.68007 0.714276 4.6016C0.807753 4.52312 0.921356 4.47242 1.04219 4.45525L4.76367 3.91335L6.40243 0.564012C6.45589 0.453627 6.53937 0.360533 6.64329 0.295395C6.74721 0.230257 6.86738 0.195709 6.99003 0.195709C7.11268 0.195709 7.23285 0.230257 7.33678 0.295395C7.4407 0.360533 7.52417 0.453627 7.57764 0.564012L9.23598 3.90682L12.9575 4.44872C13.0783 4.46589 13.1919 4.51659 13.2854 4.59507C13.3789 4.67354 13.4485 4.77665 13.4863 4.89268C13.5209 5.00607 13.5241 5.12676 13.4953 5.24178C13.4665 5.3568 13.407 5.46183 13.3231 5.54558L10.6332 8.15714L11.2861 11.8329C11.3094 11.9553 11.2972 12.0818 11.2509 12.1975C11.2046 12.3132 11.1262 12.4133 11.0249 12.4858C10.9067 12.5687 10.7643 12.61 10.6201 12.6033Z"
										className={tab === TabType.ALL ? 'fill-[url(#g8)]' : 'fill-color-6'}
									/>
									<defs>
										<linearGradient
											id="g8"
											x1="13.8192"
											y1="-1.73486"
											x2="-0.371586"
											y2="-0.919445"
											gradientUnits="userSpaceOnUse"
										>
											<stop stopColor="#E8B05D" />
											<stop offset="0.28125" stopColor="#ED6F4D" />
											<stop offset="0.807292" stopColor="#7765CB" />
											<stop offset="1" stopColor="#4F81E2" />
										</linearGradient>
									</defs>
								</svg>
							}
							onSelect={setTab}
						/>
					</div>
				</div>
				<div className="col-span-full lg:col-span-12 2xl:col-span-13 lg:mt-10 flex flex-row gap-4 md:gap-10 flex-wrap justify-center">
					{renderTabs}
				</div>
			</div>
		</Layout>
	)
}

//* Taken and modified from '/byPage/profile.tsx'
export const CreatorsCard: FC<{ user: User; featured?: boolean }> = ({ user, featured }) => {
	const [, width] = useWindowSize()

	const followersDisplayCount = width < 768 ? 5 : 10

	const { data: profile, isLoading } = useProfile(user?.wallet_address)
	return (
		<Link href={user.user_name ?? user.name ?? user.wallet_address} passHref>
			<div
				className={clsx(
					'rounded-4xl h-[15.5rem] md:h-[27rem] w-40 md:w-[20rem] px-3 md:px-10 flex flex-col gap-2.5 md:gap-5 justify-center items-center py-3 md:py-5 cursor-pointer',
					featured ? 'bg-light-5 dark:bg-dark-9' : 'bg-color-2'
				)}
			>
				<Avatar
					size={width > 768 ? '2xl' : 'xl'}
					profilePicUrl={user.profile_pic_url}
					gradient1={user.gradient_1}
					gradient2={user.gradient_2}
				/>
				<div className="flex flex-col items-center gap-2.5 w-full">
					<span
						className={clsx(
							'text-xl md:text-2xl w-full truncate text-center',
							featured ? 'text-color-3' : 'text-color-8'
						)}
					>
						{user.name}
					</span>
					<Tag
						className={clsx(
							'text-[17px] font-semibold hidden md:block',
							featured ? 'bg-color-8 text-light-2 dark:text-dark-3' : 'bg-color-1 text-color-6'
						)}
						label={`@${user.user_name ?? shortenAddressWithNoDots(user.wallet_address)}`}
					/>
				</div>
				<span
					className={clsx(
						'text-[15px] font-normal truncate w-full text-center hidden md:block',
						featured ? 'text-color-3' : 'text-color-7'
					)}
				>
					{user.bio}
				</span>
				<div className="flex flex-col gap-2.5 items-center">
					{isLoading ? (
						<Skeleton />
					) : (
						<UserCircles clickable={false} size="sm" users={profile?.followers.slice(0, followersDisplayCount)} />
					)}
					{user.followers > followersDisplayCount && (
						<span
							className={clsx(
								'text-xs md:text-[13px] font-semibold uppercase text-center',
								featured ? 'text-light-3 dark:text-dark-4 ' : 'text-color-6'
							)}
						>
							+{user.followers - followersDisplayCount} MORE MEMBERS
						</span>
					)}
				</div>
			</div>
		</Link>
	)
}

enum TabType {
	FEATURED = 'featured',
	ALL = 'all',
}

const AllCreators: FC = () => {
	const { data, isLoading } = useInfiniteUsers()

	return (
		<>
			{isLoading ? (
				<div className="col-span-full">
					<StyledLoading />
				</div>
			) : (
				data?.pages?.map((grp) => grp.users.map((user, i) => <CreatorsCard key={i} user={user} />))
			)}
		</>
	)
}

const FeaturedCreators: FC = () => {
	const { data, isLoading } = useFeaturedUsers()

	return (
		<>
			{isLoading ? (
				<div className="col-span-full">
					<StyledLoading />
				</div>
			) : (
				data?.pages?.map((grp) => grp.users.map((user, i) => <CreatorsCard key={i} user={user} featured />))
			)}
		</>
	)
}

export default Creators
