import backend from 'apiUtils/backend'
import { UserResult } from 'interfaces/interfaces'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { QueryFunctionContext, useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import { getMilliFromMinutes } from 'utils/rq-settings'

const getFeaturedUsers = async (skip?: number): Promise<UserResult> => {
	const queryParams = new URLSearchParams()
	queryParams.append('limit', '24')
	if (skip) queryParams.append('skip', skip.toString())

	return backend.get(`/user/featured?${queryParams.toString()}`).then((res) => res.data)
}

const useFeaturedUsers = (): UseInfiniteQueryResult<UserResult, unknown> => {
	const query = useInfiniteQuery<UserResult>(
		'featured-users',
		({ pageParam: skip }: QueryFunctionContext<string, number>) => getFeaturedUsers(skip),
		{
			getNextPageParam: (lastPage) => lastPage?.nextSkip,
			staleTime: getMilliFromMinutes(10),
			cacheTime: getMilliFromMinutes(10),
		}
	)

	useBottomScrollListener(() => {
		if (query.hasNextPage && !query.isFetchingNextPage) {
			query.fetchNextPage()
		}
	})

	return query
}

export default useFeaturedUsers
