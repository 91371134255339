import { WidgetsIcon } from '@components/icons'
import useDashboardTab, { DashboardView } from '@hooks/query-param/useDashboardTab'
import { SetQuery } from '@hooks/useQueryParam'
import clsx from 'clsx'
import { FC } from 'react'

export interface TabProps<T extends string> {
	imageComponent: JSX.Element
	id: T
	onSelect: SetQuery<T>
	currentTab: T
}

export const Tab = <T extends string>({ imageComponent, id, onSelect, currentTab }: TabProps<T>): JSX.Element => {
	return (
		<div
			className={clsx(
				'flex flex-col lg:flex-row items-center rounded-2xl lg:rounded-full py-3 lg:px-2 lg:py-2 gap-2 cursor-pointer group smooth-hover w-1/3 lg:w-auto',
				currentTab === id
					? 'bg-color-3 lg:bg-color-8 text-color-8 lg:text-color-1'
					: 'text-color-6 hover:text-color-8 focus:text-color-8 border lg:border-0 border-color-4'
			)}
			onClick={() => onSelect(id)}
			role="button"
			tabIndex={0}
			onKeyPress={(e) => (['Enter', ' '].includes(e.key) ? onSelect(id) : null)}
		>
			{imageComponent}
			<h1 className="font-semibold text-sm text-inherit tracking-wide uppercase">{id}</h1>
		</div>
	)
}

const Tabs: FC = () => {
	const [tab, setTab] = useDashboardTab()

	return (
		<div className="flex items-center justify-between w-full gap-4 lg:gap-0 lg:w-1/2 xl:w-[47%] 2xl:w-2/5 px-4 py-3 rounded-full lg:bg-color-0">
			<Tab
				id={DashboardView.Widgets}
				onSelect={setTab}
				currentTab={tab}
				imageComponent={
					<WidgetsIcon
						className={clsx(tab !== DashboardView.Widgets && 'group-hover:fill-color-8 fill-color-6', 'smooth-hover')}
						gradient={tab === DashboardView.Widgets}
					/>
				}
			/>
			<Tab
				id={DashboardView.Token}
				onSelect={setTab}
				currentTab={tab}
				imageComponent={
					<svg className="w-7 h-7" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle
							cx="13"
							cy="13"
							r="12"
							className={clsx(
								'smooth-hover',
								tab === DashboardView.Token
									? 'stroke-[url(#g3)]'
									: 'stroke-color-6 group-hover:stroke-color-8 group-focus:stroke-color-8'
							)}
							strokeWidth="2"
						/>
						<circle
							cx="13"
							cy="13"
							r="8.5"
							className={clsx(
								'smooth-hover',
								tab === DashboardView.Token
									? 'stroke-[url(#g2)]'
									: 'stroke-color-6 group-hover:stroke-color-8 group-focus:stroke-color-8'
							)}
							strokeDasharray="2 2"
						/>
						<defs>
							<linearGradient
								id="g3"
								x1="26.6075"
								y1="-4.04443"
								x2="-1.71761"
								y2="-2.49419"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#E8B05D" />
								<stop offset="0.28125" stopColor="#ED6F4D" />
								<stop offset="0.807292" stopColor="#7765CB" />
								<stop offset="1" stopColor="#4F81E2" />
							</linearGradient>
							<linearGradient
								id="g2"
								x1="22.4206"
								y1="1.20001"
								x2="2.81088"
								y2="2.27326"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#E8B05D" />
								<stop offset="0.28125" stopColor="#ED6F4D" />
								<stop offset="0.807292" stopColor="#7765CB" />
								<stop offset="1" stopColor="#4F81E2" />
							</linearGradient>
						</defs>
					</svg>
				}
			/>
			<Tab
				id={DashboardView.Manage}
				onSelect={setTab}
				currentTab={tab}
				imageComponent={
					<svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
						<g clipPath="url(#clip0_3824_1801)">
							<rect
								width="26"
								height="26"
								rx="13"
								className={tab === DashboardView.Manage ? 'fill-[url(#g5)]' : 'fill-color-3'}
							/>
							<path
								d="M6.71484 20.0078V29.5789"
								className={
									tab === DashboardView.Manage
										? 'stroke-color-3 lg:stroke-color-8'
										: 'stroke-color-7 group-hover:stroke-color-8 smooth-hover'
								}
								strokeWidth="2.9011"
								strokeLinecap="square"
							/>
							<path
								d="M13.001 11.398L13.001 28.9018"
								className={
									tab === DashboardView.Manage
										? 'stroke-color-3 lg:stroke-color-8'
										: 'stroke-color-7 group-hover:stroke-color-8 smooth-hover'
								}
								strokeWidth="2.9011"
								strokeLinecap="square"
							/>
							<path
								d="M19.2861 15.4942V25.0653"
								className={
									tab === DashboardView.Manage
										? 'stroke-color-3 lg:stroke-color-8'
										: 'stroke-color-7 group-hover:stroke-color-8 smooth-hover'
								}
								strokeWidth="2.9011"
								strokeLinecap="square"
							/>
						</g>
						<defs>
							<linearGradient
								id="g5"
								x1="26.6075"
								y1="-4.04443"
								x2="-1.71761"
								y2="-2.49419"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#E8B05D" />
								<stop offset="0.28125" stopColor="#ED6F4D" />
								<stop offset="0.807292" stopColor="#7765CB" />
								<stop offset="1" stopColor="#4F81E2" />
							</linearGradient>
							<clipPath id="clip0_3824_1801">
								<rect width="26" height="26" rx="13" fill="white" />
							</clipPath>
						</defs>
					</svg>
				}
			/>
		</div>
	)
}

export default Tabs
