import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { QueryFunctionContext, useInfiniteQuery, UseInfiniteQueryResult } from 'react-query'
import backend from '../../apiUtils/backend'
import { UserResult } from '../../interfaces/interfaces'
import { getMilliFromMinutes } from '../../utils/rq-settings'

const getPaginatedUsers = async (skip?: number): Promise<UserResult> => {
	const queryParams = new URLSearchParams()
	queryParams.append('limit', '24')
	if (skip) queryParams.append('skip', skip.toString())
	return backend.get('/user/all?' + queryParams.toString()).then((res) => res.data)
}

const useInfiniteUsers = (): UseInfiniteQueryResult<UserResult, unknown> => {
	const query = useInfiniteQuery<UserResult>(
		'home_users',
		({ pageParam: skip }: QueryFunctionContext<string, number>) => getPaginatedUsers(skip),
		{
			getNextPageParam: (lastPage) => lastPage?.nextSkip,
			staleTime: getMilliFromMinutes(1),
			cacheTime: getMilliFromMinutes(10),
		}
	)

	useBottomScrollListener(() => {
		if (query.hasNextPage || !query.isFetchingNextPage) {
			query.fetchNextPage()
		}
	})

	return query
}

export default useInfiniteUsers
