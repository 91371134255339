import { getStringFromStringOrArray } from 'helpers/utils'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

export interface SetQuery<T extends string> {
	(value: T, extraQueries?: Record<string, string>): void
}

interface UseQueryConfig<T extends string> {
	defaultValue?: T
	allowedValues?: T[]
}

export type UseQueryParamReturn<T extends string> = [T, SetQuery<T>]

/**
 * A hook for state management using query params
 *
 * @param name The name of the query param
 * @param config An object with the use query config
 * @returns [queryValue, setQuery]
 */
const useQueryParam = <T extends string>(name: string, config: UseQueryConfig<T> = {}): UseQueryParamReturn<T> => {
	const router = useRouter()

	// raw value from router
	const rawValue = getStringFromStringOrArray(router.query[name]) as T
	const hasNoValue = rawValue == null

	const setQuery = useCallback<SetQuery<T>>(
		async (value, extraQueries = {}) => {
			router.push(
				{ pathname: router.pathname, query: { ...router.query, [name]: value, ...extraQueries } },
				undefined,
				{
					shallow: true,
					scroll: false,
				}
			)
		},
		[router, name]
	)

	// Replace with default value if no value is set
	useEffect(() => {
		if (
			router.isReady &&
			((config.defaultValue && hasNoValue) || (config.allowedValues && !config.allowedValues.includes(rawValue)))
		) {
			router.replace(
				{ pathname: router.pathname, query: { ...router.query, [name]: config.defaultValue } },
				undefined,
				{
					shallow: true,
					scroll: false,
				}
			)
		}
	}, [config.allowedValues, config.defaultValue, hasNoValue, name, rawValue, router])

	// Not returning default value because it may have inconsistencies with server side rendering
	return [rawValue, setQuery]
}

export default useQueryParam
