import useQueryParam, { UseQueryParamReturn } from '@hooks/useQueryParam'

export enum DashboardView {
	Widgets = 'widgets',
	Manage = 'manage',
	Token = 'token',
}

const useDashboardTab = (): UseQueryParamReturn<DashboardView> => {
	const dashboardViews = Object.values(DashboardView)
	return useQueryParam<DashboardView>('tab', {
		defaultValue: DashboardView.Widgets,
		allowedValues: dashboardViews,
	})
}

export default useDashboardTab
